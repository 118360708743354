<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.faultSetting')"
    id="faultSetting"
  >
    <div id="outer-title">{{ $t("i18n.faultSetting") }}</div>
    <div class="item">
      <div v-for="item in ctrlTypes" :key="item">
        <label>{{ item }}</label>
        <el-button type="primary" @click="enterSettingPage(item)">
          {{ $t("i18n.setUp") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "FaultSetting",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      ctrlTypes: [],
    });

    const init = async () => {
      let { data } = await proxy.$api.rescue.getCtrlTypes();
      state.ctrlTypes = data;
    };

    const enterSettingPage = (item) => {
      let routeData = router.resolve({
        path: "/system/setFaultPage",
        query: { title: item },
      });
      window.open(routeData.href, "_blank");
    };

    init();

    return {
      ...toRefs(state),
      enterSettingPage,
    };
  },
};
</script>

<style lang="scss">
#faultSetting {
  .item {
    > div {
      margin-bottom: 20px;
      label {
        font-size: 14px;
        margin-right: 20px;
        width: 55%;
        display: inline-block;
        text-align: right;
      }
    }
  }
}
</style>
